import React from 'react';
import './Terms.css';


export const Terms = () => { 

    return (
        <div className="Termos">
            <div className="terms">
                <h4>TERMOS GERAIS DE USO DA PLATAFORMA CONECTARPLAY</h4>
                <br />
               
                <p>1. Definições iniciais</p>
<p>1.1. Para o perfeito entendimento e interpretação do Termo de Uso e seu Anexo de Proteção de Dados, fica estabelecido que:</p>
<p>1.1.1. LICENCIANTE: i8 TECH LTDA, pessoa jurídica de direito privado, com sede na cidade de Taubaté, São Paulo, na Rua Itaboaté nº, 86 – Sala 3, Bairro Vila Olímpia, CEP 12.062-717, inscrita no CNPJ sob o número 54.464.034/0001-66.</p>
<p>1.1.2. LICENCIADO: VOCÊ, usuário do sistema (pessoa física ou jurídica) que tenha permissão para utilizar os serviços do SOFTWARE</p>
<p>1.1.3. SOFTWARE: software de propriedade exclusiva da LICENCIANTE, cujas funcionalidades e serviços estão disponibilizados pelo site “”, por meio do qual as INFORMAÇÕES do LICENCIADO serão fornecidas diretamente por ele ou coletadas diretamente nos sites das instituições de maneira automatizada.</p>
<p>1.1.4. API: Application Programming Interface que em português significa Interface de Programação de Aplicativos. É um conjunto de rotinas e padrões de programação para acesso a um aplicativo de software ou plataforma baseado na Web.</p>
<p>1.1.5. INFORMAÇÕES: entende-se todas as informações do LICENCIADO relacionadas a INFORMAÇÕES PESSOAIS, INFORMAÇÕES FINANCEIRAS e INFORMAÇÕES DE CONTA.</p>
<p>1.1.6. TUCL: Termo de Uso e Contrato de Licença.</p>
<p>1.1.7. SUPORTE: é o serviço de suporte técnico disponibilizado ao LICENCIADO;</p>
<p>1.1.8. CONECTAR: software desenvolvido e registrado em nome da i8 Tech, que possibilita o controle e o gerenciamento das informações e dados internos de empresas, com acesso remoto via rede mundial de computadores, por meio de por meio de dispositivos próprios, cuja responsabilidade são do LICENCIADO;</p>
<p>1.1.9. LEI DE PROTEÇÃO DE DADOS: leis e regulamentos relacionados ao processamento e proteção de dados pessoais aplicáveis no país onde a LICENCIANTE está estabelecida;</p>
<p>1.1.10. i8 Tech: pessoa jurídica, desenvolvedora de sistemas detentora de todos os direitos do SOFTWARE.</p>
<p>2. Do Objetivo</p>
<p>2.1. Este Termo de Uso e Contrato de Licença (“TUCL”) é um acordo legal entre o LICENCIADO e LICENCIANTE para uso do programa de computador denominado CONECTAR, disponibilizado neste ato pela LICENCIANTE por meio do site “conectarplay.com”, determinado pelo LICENCIADO no ato do licenciamento do SOFTWARE, compreendendo o programa de computador e podendo incluir os meios físicos associados, bem como quaisquer materiais impressos e qualquer documentação online ou eletrônica. Ao utilizar o SOFTWARE, mesmo que parcialmente ou a título de teste, o LICENCIADO estará vinculado aos termos deste TUCL, concordando com suas disposições, principalmente com relação ao CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do LICENCIADO pela LICENCIANTE, necessárias para a integral execução das funcionalidades ofertadas pelo SOFTWARE. Em caso de discordância com os termos aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida pelo LICENCIADO, devendo ser comunicada a imediatamente a licenciante.</p>
<p>3. Do serviço</p>
<p>3.1. O serviço oferecido pela LICENCIANTE, objeto deste Termo de Uso e Contrato de Licença (“TUCL”), consiste na coleta e gestão de informações obtidas na internet por meio do CNPJ do LICENCIADO, em especial aos dados e arquivos (xml) emitidos em face do CNPJ do LICENCIADO em órgãos públicos.</p>
<p>4. Da Propriedade Intelectual</p>
<p>4.1. O LICENCIADO não adquire, pelo presente instrumento, nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, relacionados ao SOFTWARE ou parte dele. O LICENCIADO também não adquire nenhum direito sobre ou relacionado ao SOFTWARE ou qualquer componente dele, além dos direitos expressamente licenciados sobre esse TUCL.</p>
<p>4.2. Todo o conteúdo do SOFTWARE será de propriedade exclusiva da LICENCIANTE, exceto as informações incluídas no SOFTWARE pelo próprio LICENCIADO.</p>
<p>4.3. É concedido ao LICENCIADO os serviços e direitos de uso do SOFTWARE em caráter não permanente, não exclusivo e com vigência atrelada a esse TUCL.</p>
<p>4.4. Também será de propriedade exclusiva da LICENCIANTE, ou a esta devidamente licenciado, todo o conteúdo disponibilizado no site, incluindo, mas não se limitando a textos, gráficos, imagens, logos,ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material.</p>
<p>5. Declarações do LICENCIADO</p>
<p>5.1. O LICENCIADO declara ter pleno conhecimento dos direitos e obrigações decorrentes do presente TUCL, constituindo o instrumento de acordo completo entre as partes. Declara, também, ter lido, compreendido e aceitado todos os termos e condições deste TUCL.</p>
<p>5.2. O LICENCIADO declara que leu, compreendeu e aceitou a política de privacidade, consentindo livre e expressamente às ações de coleta, uso, armazenamento e tratamento das referidas informações e dados, tudo de acordo com a legislação vigente à época.</p>
<p>5.3. O LICENCIADO declara estar ciente de que as operações que correspondam à aceitação do presente TUCL, de determinadas condições e opções, bem como eventual rescisão do presente instrumento e demais alterações, serão registradas nos bancos de dados da LICENCIANTE, juntamente com a data e hora em que foram realizadas pelo LICENCIADO, podendo tais informações serem utilizadas como prova pelas partes, independentemente do cumprimento de qualquer outra formalidade.</p>
<p>5.4. O LICENCIADO declara que está ciente de que, em qualquer hipótese, deve atender rigorosamente a legislação, especialmente no que se refere às suas obrigações tributárias, fiscais, trabalhistas e previdenciárias, seja de natureza principal ou acessória, bem como cumprir a regulação de proteção de dados aplicável e quaisquer outras, entendendo que o SOFTWARE objeto deste TUCL trata-se de uma condição de meio e não de resultado, não dispensando, portanto, a correta alimentação das informações e parametrizações necessárias pelo LICENCIADO com base na legislação em vigor.</p>
<p>5.5. O LICENCIADO poderá acessar aplicativo de terceiros através de API’s de conexão com a finalidade de compartilhar suas Informações ou por meio de download sobre determinados Aplicativos Terceiros. O LICENCIADO compreende e concorda que tais Informações poderão ser atribuídas à sua conta no Aplicativo de Terceiros e poderão ser publicadas em tal serviço. Embora essas informações possam ser automaticamente compartilhadas por definição, o LICENCIADO poderá, a qualquer tempo, suspender o compartilhamento ou quando for o caso desinstalar tais Aplicativos Terceiros. O Aplicativo de Terceiros também poderá fornecer controles semelhantes, porém o acesso deverá ser feito diretamente através do Aplicativo de Terceiros, objetivando suspender tal acesso.</p>
<p>5.6. O LICENCIADO compreende e concorda que o uso de suas Informações por um Aplicativo de Terceiros coletadas junto ao mesmo (ou conforme autorizado pelo LICENCIADO) é regido pelas políticas de privacidade do Aplicativo de Terceiros e por suas configurações no respectivo serviço, e o uso de tais informações por parte do LICENCIADO é regido por este Termo de Uso e pelas configurações da sua conta no SOFTWARE da LICENCIANTE. A LICENCIANTE não será responsável, sob qualquer hipótese, pelo tratamento dado pelas informações por um Aplicativo Terceiro.</p>
<p>5.7. O LICENCIADO declara ainda que está ciente de que para usufruir de algumas das funcionalidades do SOFTWARE, deverá disponibilizar à LICENCIANTE as INFORMAÇÕES DE CONTA para que o SOFTWARE, de maneira automatizada, colete informações diretamente nos sites e/ou outros meios virtuais disponibilizados pelas instituições financeiras, com as quais mantenha relacionamento, agindo a LICENCIANTE, neste caso, como representante e mandatária do LICENCIADO nestes atos.</p>
<p>5.8. Compartilhamos informações relacionadas aos nossos usuários com terceiros selecionados que nos fornecem uma variedade de serviços que suportam a entrega dos nossos serviços (chamaremos eles de “Processadores Terceirizados”). Esses Processadores Terceirizados variam de fornecedores de infraestrutura técnica a serviço de atendimento ao LICENCIADO e ferramentas de autenticação. Asseguramos que o gerenciamento de informações feito em nosso nome por Processadores Terceirizados será feito de acordo com termos contratuais, que requerem que essas informações sejam mantidas seguras, sejam processadas de acordo com as leis de proteção de dados vigente à época do contrato e usadas somente conforme instruímos e não para os propósitos dos Processadores Terceirizados (a menos que tenham a sua autorização).</p>
<p>5.9. Processadores Terceirizados podem estar alocados ou processar suas informações fora do país onde você está baseado. Nos casos em que o nosso uso de Processadores Terceirizados envolver a transferência de dados pessoais nós tomaremos as medidas necessárias para garantir que os dados pessoais sejam devidamente protegidos.</p>
<p>5.10. Os tipos de Processadores Terceirizados com os quais podemos compartilhar elementos dos seus dados pessoais incluem:</p>
<p>5.11. Processadores de pagamento acionados por nós para armazenar ou gerenciar informações de pagamento com segurança, como detalhes de cartão de crédito ou débito.</p>
<p>5.12. Fornecedores de gerenciamento de e-mail e ferramentas de distribuição, por exemplo, se você assinar o recebimento de newsletters do LICENCIANTE ou outras mensagens comerciais, gerenciaremos seu envio usando uma ferramenta terceirizada de distribuição de e-mail;</p>
<p>5.13. Fornecedores de serviços de segurança e prevenção de fraudes. Por exemplo, usamos esses fornecedores para identificar agentes de software automatizado que podem prejudicar nossos serviços ou para prevenir o uso indevido de nossas APIs;</p>
<p>5.14. Fornecedores de plataformas de software que nos ajudam na comunicação ou no fornecimento de serviços de atendimento ao LICENCIADO, por exemplo, gerenciamos e respondemos todas as mensagens enviadas por meio da nossa central de ajuda usando uma ferramenta terceirizada de gerenciamento de comunicações;</p>
<p>5.15. Fornecedores relacionados ao sistema bancário;</p>
<p>5.16. Fornecedores de serviços de armazenamento na nuvem online e outros serviços de TI essenciais; e</p>
<p>5.17. Quando processarmos seus dados pessoais solicitaremos o seu consentimento, mas sempre que você optar por retirar a autorização, poderá exercer esse direito a qualquer momento usando a funcionalidade disponível no recurso respectivo do produto ou entrando em contato através do seguinte endereço eletrônico: suporte@conectarplay.com. A partir do momento que você retira alguma autorização poderá ficar impedido de gerar automaticamente alguma atividade que era realizada de forma automatizada. Ao revogar seu consentimento, você não vai mais poder usar os serviços e recursos que exigem a coleta ou o uso das informações que coletávamos ou usávamos com base no consentimento.</p>
<p>5.18. O LICENCIADO assume total responsabilidade por toda ou qualquer informação que tenha sido inserida por ele no SOFTWARE.</p>
<p>6. Privacidade</p>
<p>6.1. O LICENCIADO concede autorização para que a LICENCIANTE possa realizar a análise dos dados e informações disponibilizadas pelo LICENCIADO e armazenadas no SOFTWARE pelo LICENCIANTE.</p>
<p>6.2. Fica vedado à LICENCIANTE utilizar e divulgar para quaisquer terceiros as informações sem prévia solicitação e autorização expressa do LICENCIADO.</p>
<p>6.3. O LICENCIADO consente livre e expressamente que a LICENCIANTE utilize cookies apenas para controlar a audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e personalizados ao perfil do LICENCIADO. A LICENCIANTE garante que estas informações coletadas por meio de cookies serão estatísticas e não pessoais, bem como, que não serão utilizadas para propósitos diversos dos expressamente previstos neste TUCL, comprometendo-se a adotar todas as medidas necessárias a fim de evitar o acesso e o uso de tais informações por qualquer terceiros, sem a devida autorização.</p>
<p>7. Do acesso ao software</p>
<p>7.1. Este termo concede ao LICENCIADO uma licença revogável, onerosa, não exclusiva e intransferível de uso do software, sendo certo que o LICENCIADO não poderá utilizar e nem permitir o uso do software para qualquer outra finalidade não expressamente prevista neste TUCL.</p>
<p>7.2. Por meio do software o LICENCIADO, poderá efetuar as emissões documentos fiscais como Nota Fiscal Eletrônica e Cupom Fiscal Eletrônico, quando por ele estiverem sido contratado, pois são módulos independentes e complementares ao software contratado.</p>
<p>7.3. O LICENCIADO é o responsável pela veracidade, validade e precisão das informações por ele fornecidas no seu cadastro, inclusive em relação à indicação de endereço de e-mail válido e de sua titularidade, competindo-lhe, ainda, manter o referido cadastro sempre atualizado.</p>
<p>7.4. A LICENCIANTE não terá qualquer obrigação de policiar ou fiscalizar as informações fornecidas pelo LICENCIADO.</p>
<p>7.5. O login e senha criados pelo LICENCIADO para acesso ao SOFTWARE são confidenciais e de responsabilidade exclusiva do LICENCIADO, que deverá entrar em contato com a i8 Tech, imediatamente, na hipótese de comprometimento do seu sigilo.</p>
<p>7.6. Em nenhuma hipótese, o LICENCIADO terá acesso ao código fonte do software, por este se tratar de propriedade intelectual da i8 Tech.</p>
<p>8. Limitações de uso e Obrigações do LICENCIADO</p>
<p>8.1. O LICENCIADO não poderá, em hipótese alguma:</p>
<p>8.1.1. Empregar softwares, técnicas e/ou artifícios com o intuito de utilizar indevidamente o Software para práticas nocivas ao SOFTWARE ou aos seus usuários, tais como exploits, spamming, flooding, spoofing, crashing, root kits, etc.;</p>
<p>8.1.2. Reproduzir, adaptar, modificar e/ou empregar, total ou parcialmente, para qualquer fim, o Software sem prévia autorização expressa da i8 Tech;</p>
<p>8.1.3. Utilizar o Software de qualquer forma que possa implicar em ato ilícito, infração, violação de direitos ou danos à LICENCIANTE ou terceiros, incluindo, mas não limitando ao uso para invasão de dispositivo informático com o objetivo de obter, adulterar ou destruir dados ou informações sem a autorização expressa do titular de tais dados ou do dispositivo ou servidor nos quais estes estejam armazenados;</p>
<p>8.1.4. Copiar, ceder, sublicenciar, vender ou locar, reproduzir, doar, gratuita ou onerosamente, provisória ou permanentemente o Software, assim como partes, manuais ou informações relativas ao mesmo;</p>
<p>8.1.5. Publicar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de tróia ou qualquer outro programa contaminante ou destrutivo que possa interferir no funcionamento do Software; e,</p>
<p>8.1.6. Realizar engenharia reversa.</p>
<p>8.2. Obriga-se o LICENCIADO a:</p>
<p>8.2.1. Manter pessoal treinado para a operação do SOFTWARE e para a comunicação com a LICENCIANTE e prover, sempre que ocorrerem quaisquer problemas com o SOFTWARE, toda a documentação, relatórios e demais informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os trabalhos;</p>
<p>8.2.2. Manter linha de telecomunicação, modem, internet, programa de comunicação, endereço de correio eletrônico e outros recursos necessários à comunicação com a LICENCIANTE;</p>
<p>8.2.3. Responder pelas informações inseridas no sistema, pelo cadastramento, permissões, senhas e modo de utilização de seus usuários;</p>
<p>8.2.4. Responsabilizar-se pelo conteúdo exclusivo de uso sobre o SOFTWARE, incluindo a designação das pessoas e funcionários do LICENCIANTE que terão acesso aos seus dados e informações no SOFTWARE, não sendo, portanto, estas informações revisadas em momento algum;</p>
<p>8.2.5. Certificar-se de que não está proibido por determinação legal e/ou contratual de passar INFORMAÇÕES, INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS, bem como quaisquer outros dados à LICENCIANTE, necessários para a execução do serviço oferecido por este TUCL;</p>
<p>8.2.6. Garantir a regularidade dos dados pessoais inseridos no SOFTWARE, em especial, no que tange ao cumprimento de bases legais e direitos dos titulares de dados;</p>
<p>8.2.7. A qualquer tempo a LICENCIANTE poderá bloquear acesso ao SOFTWARE caso constate qualquer prática pelo LICENCIADO ou terceiros de violação ao presente Termo de uso e/ou qualquer tentativa de fraude ou dê a entender tratar-se de uma tentativa, não reduzindo essa ação a responsabilidade do LICENCIADO sobre seus atos;</p>
<p>8.2.8. Informar a LICENCIANTE sempre que houver qualquer alteração das INFORMAÇÕES fornecidas à esta e que possam impedir, limitar ou prejudicar o acesso da LICENCIANTE às INFORMAÇÕES necessárias para a execução das funcionalidades ofertadas pelo SOFTWARE;</p>
<p>8.2.9. Atender rigorosamente a legislação brasileira e toda obrigação legal imposta e/ou decorrente de sua atividade e em razão da utilização deste SOFTWARE;</p>
<p>9. Das obrigações da LICENCIANTE</p>
<p>9.1. Obriga-se a LICENCIANTE a:</p>
<p>9.1.1. A LICENCIANTE garante ao LICENCIADO que o SOFTWARE deverá funcionar regularmente, se respeitadas as condições de uso definidas na documentação. Na ocorrência de falhas de programação (“bugs”), a LICENCIANTE obrigar-se-á a corrigir tais falhas;</p>
<p>9.1.2. A LICENCIANTE manterá atualizada as funções existentes no SOFTWARE com relação às variáveis normalmente alteradas pela legislação federal. A interpretação legal das normas editadas pelo governo será efetuada com base no entendimento majoritário dos “usuários” do SOFTWARE, doutrinadores e jurisprudência pátria. Ficam excluídas da manutenção as alterações no SOFTWARE originárias de obrigações assumidas pelo LICENCIADO junto a sindicatos ou associações, tais como convenções coletivas de trabalho e outras, bem como necessidades específicas de certos segmentos, além do atendimento de legislações municipais e/ou estaduais.</p>
<p>9.1.3. Fornecer, ato contínuo ao aceite deste TUCL, acesso ao SOFTWARE durante a vigência deste TUCL;</p>
<p>9.1.4. Suspender o acesso ao SOFTWARE do LICENCIADO que esteja desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em vigor;</p>
<p>9.1.5. Alterar as especificações e/ou características do SOFTWARE para a melhoria e/ou correções de erros;</p>
<p>9.1.6. Disponibilizar acesso aos serviços de suporte compreendido de segunda a sexta-feira das 9h às 12h e das 13:15h às 17h (pelo horário de Brasília), via endereço eletrônico (suporte@conectarplay.com);</p>
<p>9.1.7. Armazenar e disponibilizar as informações do LICENCIADO, relacionadas ao conteúdo oriundo da utilização do SOFTWARE.</p>
<p>9.1.8. Manter as INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS do LICENCIADO, bem como seus registros de acesso, em sigilo, sendo que as referidas INFORMAÇÕES serão armazenadas em ambiente seguro, sendo respeitadas a intimidade, a vida privada, a honra e a imagem do LICENCIADO, em conformidade com as disposições da Lei nº 12.965/2014. A LICENCIANTE não será responsável pelo armazenamento de informações excluídas pelo LICENCIADO.</p>
<p>9.1.9. A LICENCIANTE será responsável pela definição dos meios de tratamento de dados pessoais e fornecer informações sobre esses meios ao LICENCIADO, especificamente para permitir que LICENCIADO possa elaborar registros e informar aos Titulares dos Dados conforme exigido pela Legislação de Proteção de Dados aplicável.</p>
<p>9.1.10. A LICENCIANTE pode coletar e processar os dados pessoais somente dentro da estrutura deste TERMO DE USO para melhorar e atualizar esses serviços, não sendo responsável pelo cumprimento de nenhuma Lei de Proteção de Dados aplicável ao LICENCIADO ou ao setor dele que não seja geralmente aplicável à LICENCIANTE .</p>
<p>9.1.11. Por meio do presente TERMO DE USO, a LICENCIANTE reconhece expressamente que deve processar os Dados Pessoais somente para o fornecimento dos serviços do SOFTWARE, bem como para a melhoria e atualização do referido software.</p>
<p>9.1.12. A LICENCIANTE será responsável pela implementação de medidas de segurança para o tratamento de dados pessoais no âmbito do SOFTWARE e tomará as medidas técnicas e organizativas apropriadas, destinadas a proteger os dados pessoais do LICENCIADO contra uso indevido e perda, ou contra qualquer outra violação de proteção de dados, de acordo com as Leis de Proteção de Dados aplicáveis.</p>
<p>9.1.13. Em caso de violação de proteção de dados, a LICENCIANTE poderá auxiliar, caso requisitada, o LICENCIADO a fornecer todas as informações necessárias a que tenha acesso, a fim de permitir que o LICENCIADO cumpra suas obrigações. A LICENCIANTE informará ao LICENCIADO, sem demora indevida, em caso de qualquer violação dos Dados Pessoais do LICENCIADO descoberta pela LICENCIANTE.</p>
<p>9.1.14. Os dados pessoais tratados no âmbito do SOFTWARE são geralmente retidos até que a) sejam excluídos pelo LICENCIADO ou pelos Usuários do SOFTWARE, ou b) um período de retenção tenha expirado, ou c) o Termo de Uso do i8 Tech seja encerrado.</p>
<p>9.1.15. O LICENCIADO não pode exigir a exclusão de Dados Pessoais, uma vez que uma Lei ou regras aplicáveis exija que a LICENCIANTE mantenha materiais que contenham esses Dados Pessoais. Quando a LICENCIANTE precisar reter Dados Pessoais por tais razões, seu processamento será restrito pela LICENCIANTE, ou terceiros por ela contratados, até que o período de retenção aplicável tenha expirado. Além disso, o tratamento dos Dados Pessoais será restrito em vez de excluir os Dados Pessoais, na extensão legalmente permitida pela Lei de Proteção de Dados Aplicáveis, em particular, se a exclusão não for razoavelmente viável ou se for somente possível com um custo desproporcional devido ao tipo específico de armazenamento. O LICENCIADO reconhece e aceita que algumas solicitações podem resultar em reivindicações de remuneração adicionais para a LICENCIANTE. A LICENCIANTE informará o LICENCIADO antes de executar a solicitação.</p>
<p>9.1.16. A LICENCIANTE será responsável por atender às solicitações do LICENCIADO para correção, exclusão, restrição e disponibilização de Dados Pessoais durante a vigência e no término do Contrato.</p>
<p>9.1.17. Caso a LICENCIANTE receba uma solicitação para o exercício de direitos de um Titular de Dados conforme a Lei de Proteção de Dados Aplicável, a LICENCIANTE encaminhará essa solicitação ao LICENCIADO, que instruirá a LICENCIANTE sem atrasos indevidos sobre como proceder. O LICENCIADO reconhece que, em caso de conflito entre o Titular de Dados e o LICENCIADO, a legislação aplicável pode forçar a LICENCIANTE a atender à solicitação do Titular dos Dados com relação à objeção do LICENCIADO. A LICENCIANTE, entretanto, não tomaria essa medida sem a devida consideração da situação legal juntamente com o LICENCIADO.</p>
<p>9.1.18. O LICENCIADO por meio da proteção de dados confirma e reconhece que, caso solicite à LICENCIANTE a exclusão dos Dados Pessoais ou a restrição de seu tratamento, pode tornar impossível o fornecimento dos produtos ou dos serviços fornecidos ou contratados. A LICENCIANTE notificará o LICENCIADO sobre tal consequência antes da execução de tal solicitação.</p>
<p>9.1.19. Ao observar que não pode tratar Dados Pessoais conforme as Instruções do LICENCIADO devido a uma exigência prevista em qualquer lei aplicável, a LICENCIANTE: (i) comunicará imediatamente o LICENCIADO acerca de tal exigência legal obedecendo ao limite permitido pela lei aplicável; e (ii) quando necessário, interromperá todo o Tratamento (exceto o mero armazenamento e manutenção da segurança dos Dados Pessoais afetados), até o momento em que o LICENCIADO emitir novas Instruções que possam ser cumpridas pela LICENCIANTE. Caso a presente disposição seja invocada, a LICENCIANTE não será responsabilizada perante o LICENCIADO nos termos do Acordo por não prestar os Serviços de Assinatura aplicáveis devidos até o momento em que o LICENCIADO emitir novas Instruções lícitas sobre o Tratamento.</p>
<p>10. Nível de Serviço</p>
<p>10.1. O LICENCIADO poderá acompanhar regularmente a disponibilidade do sistema mediante o envio de um e-mail para suporte@conectarplay.com;</p>
<p>10.2. O compromisso de nível de serviço não se aplica às circunstâncias de indisponibilidade que resultem:</p>
<p>10.2.1. De uma interrupção do fornecimento de energia elétrica ou paradas emergenciais não superiores a 2 (duas) horas ou que ocorram no período das 24h às 6:00h (horário de Brasília);</p>
<p>10.2.2. Forem causadas por fatores que fujam ao razoável controle da LICENCIANTE, inclusive casos de força maior ou de acesso à internet e problemas correlatos do LICENCIADO;</p>
<p>10.2.3. Resultem de quaisquer atos de omissões do LICENCIADO ou de terceiros;</p>
<p>10.2.4. Resultem do equipamento, software ou outras tecnologias que o LICENCIADO usar que impeçam o acesso regular do Software;</p>
<p>10.2.5. Resultem de falhas de instâncias individuais não atribuíveis à indisponibilidade do LICENCIADO;</p>
<p>10.2.6. Resultem de práticas de gerenciamento da rede que possam afetar sua qualidade;</p>
<p>10.2.7. Resultem da dificuldade de acesso devido à perda de login e senha.</p>
<p>11. Isenção de Responsabilidade da LICENCIANTE</p>
<p>11.1. A LICENCIANTE não se responsabiliza:</p>
<p>11.1.1. Por falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista culpa da LICENCIANTE;</p>
<p>11.1.2. Pelo cumprimento dos prazos legais do LICENCIADO para a entrega de documentos fiscais ou pagamentos de impostos;</p>
<p>11.1.3. Pelos danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base nas informações fornecidas pelo SOFTWARE.</p>
<p>11.1.4. Por problemas definidos como “caso fortuito” ou “força maior”, contemplados pelo Art. 393 do Código Civil Brasileiro;</p>
<p>11.1.5. Por eventuais problemas oriundos de ações de terceiros que possam interferir na qualidade do serviço;</p>
<p>11.1.6. Por danos causados a terceiros em razão de conteúdo gerado pelo LICENCIANTE através de SOFTWARE;</p>
<p>11.1.7. Pela indisponibilidade ou lentidão de aplicativos terceiros que se conectam com o SOFTWARE por meio de API ou ainda, de fornecedores de telecomunicação do LICENCIADO;</p>
<p>11.1.8. Por revisar as INFORMAÇÕES DE CONTA fornecidas pelo LICENCIADO, bem como as INFORMAÇÕES FINANCEIRAS obtidas junto aos sites e/ou meios virtuais das instituições financeiras, seja no que tange à precisão dos dados, seja quanto à legalidade ou ameaça de violação em função do fornecimento destas informações;</p>
<p>11.1.9. Por quaisquer produtos e/ou serviços oferecidos por meio dos sites e/ou meios virtuais das instituições financeiras, ainda que de maneira solidária ou subsidiária;</p>
<p>11.1.10. Por eventuais infrações legais cometidas pelo LICENCIADO, de ordem fiscal, tributária, trabalhista, previdenciária, criminal, ou qualquer outra.</p>
<p>11.1.11. Pela geração e envio de obrigações fiscais acessórias, cabendo este procedimento somente ao LICENCIADO.</p>
<p>11.1.12. O acesso às INFORMAÇÕES DE CONTA e às INFORMAÇÕES FINANCEIRAS do LICENCIADO dependem de serviços prestados pelas instituições financeiras. Sendo assim, a LICENCIANTE não assume qualquer responsabilidade quanto à qualidade, precisão, pontualidade, entrega ou falha na obtenção das referidas INFORMAÇÕES junto aos sites e/ou meios virtuais das instituições financeiras.</p>
<p>11.1.13. A LICENCIANTE adota as medidas de segurança adequadas de acordo com os padrões de mercado para a proteção das INFORMAÇÕES do LICENCIADO armazenadas no SOFTWARE, assim como para o acesso às INFORMAÇÕES FINANCEIRAS do LICENCIADO. Contudo, o LICENCIADO reconhece que nenhum sistema, servidor ou software está absolutamente imune a ataques e/ou invasões de hackers e outros agentes maliciosos, não sendo a LICENCIANTE responsável por qualquer exclusão, obtenção, utilização ou divulgação não autorizada de INFORMAÇÕES resultantes de ataques que a LICENCIANTE não poderia razoavelmente evitar por meio dos referidos padrões de segurança.</p>
<p>11.1.14. Conforme o uso do SOFTWARE pelo LICENCIADO, este pode sugerir naturezas de operações ou os impostos de acordo com o histórico de uso, especialmente na utilização das funcionalidades de configuração tributária e Automatização de Impostos. A LICENCIANTE não se responsabiliza pelo aceite e pelo preenchimento incorreto dessas informações, cabendo somente ao LICENCIADO a checagem correta de suas próprias informações inseridas, sendo necessário ao LICENCIADO sempre revisar as regras de automatização de impostos e configuração tributária com um profissional de contabilidade, considerando que o SOFTWARE oferece sugestões com base em histórico de regras de tributação conforme legislação, sendo responsabilidade exclusiva do LICENCIADO a conferência das informações de suas notas fiscais e se houve alteração de alíquotas conforme legislação tributária vigente. A LICENCIANTE ressalta que o LICENCIADO poderá revisar e atualizar as regras de configuração tributária e Automatização de Impostos a qualquer tempo para se adequar à legislação vigente. Caso as informações sejam inseridas pelo CONTADOR do LICENCIADO, mediante autorização do LICENCIADO, o CONTADOR será responsável pela veracidade das informações bem como pela conferência das alíquotas aplicáveis e adequação à legislação vigente.</p>
<p>12. Da Retomada dos Softwares</p>
<p>12.1. A LICENCIANTE se reserva o direito de cancelar imediatamente o acesso do LICENCIADO ao SOFTWARE nos casos em que o LICENCIADO usar o SOFTWARE de forma diversa daquela estipulada no presente instrumento</p>
<p>13. Das Garantias Limitadas</p>
<p>13.1. Na extensão máxima permitida pela lei em vigor, o SOFTWARE é fornecido “no estado em que se encontra” e “conforme a disponibilidade”, com todas as falhas e sem garantia de qualquer espécie.</p>
<p>13.2. A LICENCIANTE não garante que as funções contidas no SOFTWARE atendam às suas necessidades, que a operação do SOFTWARE será ininterrupta ou livre de erros, que qualquer serviço continuará disponível, que os defeitos no SOFTWARE serão corrigidos ou que o SOFTWARE será compatível ou funcione com qualquer SOFTWARE, aplicações ou serviços de terceiros.</p>
<p>13.3. Além disso, o LICENCIADO reconhece que o SOFTWARE não deve ser utilizado ou não é adequado para ser utilizado em situações ou ambientes nos quais a falha ou atrasos, os erros ou inexatidões de conteúdo, dados ou informações fornecidas pelo SOFTWARE possam levar à morte, danos pessoais, ou danos físicos ou ambientais graves, incluindo, mas não se limitando, à operação de instalações nucleares, sistemas de navegação ou de comunicação aérea, controle de tráfego aéreo, sistemas de suporte vital ou de armas.</p>
<p>14. Limitação de Responsabilidade</p>
<p>14.1. Em nenhum caso a LICENCIANTE será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o SOFTWARE, por qualquer outro motivo. Sob nenhuma circunstância a responsabilidade integral da LICENCIANTE com relação ao LICENCIADO por todos os danos excederá a quantia correspondente ao último plano de licenciamento pago pelo LICENCIADO à LICENCIANTE pela obtenção da presente licença de SOFTWARE.</p>
<p>15. Consentimento livre, expresso e informado para acesso a informações confidenciais e dados pessoais</p>
<p>15.1. O LICENCIADO, ao aceitar utilizar o SOFTWARE, além de aceitar integralmente este “TUCL”, também consente, livre e expressamente, que a LICENCIANTE colete, use, armazene e faça o tratamento de suas INFORMAÇÕES, incluindo seus dados pessoais, financeiros, bancários, de conta, os quais serão necessários para que o serviço ofertado seja prestado em sua integralidade.</p>
<p>15.2. Para tanto, o LICENCIADO consente, livre e expressamente, em fornecer os dados que permitam o acesso às INFORMAÇÕES necessárias para que o SOFTWARE execute todas as funções para as quais foi projetado.</p>
<p>15.3. Ainda, o LICENCIADO declara e reconhece que para a prestação dos serviços e funcionalidades de integração a rede bancária ofertadas pelo SOFTWARE, a LICENCIANTE acessa INFORMAÇÕES FINANCEIRAS diretamente no site e/ou outros meios virtuais das instituições financeiras, sem fazer qualquer emulação de medidas de segurança, utilizando-se apenas das INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA, bem como outras eventualmente necessárias, fornecidas pelo LICENCIADO, conforme autorizado.</p>
<p>15.4. O LICENCIADO, por meio deste TUCL e fornecendo as INFORMAÇÕES DE CONTA, autoriza e consente expressamente que a LICENCIANTE acesse suas INFORMAÇÕES DE CONTA e INFORMAÇÕES FINANCEIRAS na qualidade de mandatária.</p>
<p>15.5. A LICENCIANTE declara que todas as INFORMAÇÕES DE CONTA serão utilizadas única e exclusivamente para a visualização das informações financeiras nos sites e/ou meios virtuais das instituições financeiras, sendo absolutamente vedada a LICENCIANTE a realização de quaisquer transações.</p>
<p>15.6. O LICENCIADO consente que, ao acessar o site da LICENCIANTE, esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de protocolo de Internet, páginas visitadas e tempo médio gasto no site. Tais informações poderão ser usadas para orientar o próprio LICENCIADO e melhorar os serviços ofertados.</p>
<p>15.7. O LICENCIADO consente livre e expressamente que suas INFORMAÇÕES poderão ser transferidas a terceiros em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle da LICENCIANTE. A LICENCIANTE, contudo, compromete-se, nestes casos, a informar o LICENCIADO.</p>
<p>15.8. O LICENCIADO consente livre e expressamente que a LICENCIANTE utilize cookies apenas para controlar a audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e personalizados ao perfil do LICENCIADO. A LICENCIANTE garante que estas informações coletadas por meio de cookies são estatísticas e não pessoais, bem como que não serão utilizadas para propósitos diversos dos expressamente previstos neste TUCL, comprometendo-se a adotar todas as medidas necessárias a fim de evitar o acesso e o uso de tais informações por quaisquer terceiros, sem a devida autorização.</p>
<p>15.9. Válido somente para LICENCIADOS conectados a um contador</p>
<p>15.9.1.Com o intuito de facilitar a comunicação e o tráfego de informações entre o LICENCIADO e seu contador, diminuir entraves burocráticos e margens de erro na entrega das informações contábeis, a plataforma da LICENCIANTE permite a liberação pelo LICENCIADO de acesso direto ao SOFTWARE para seu contador, permite que sejam exportadas informações para integrar com o sistema contábil que utiliza. A conexão acontece com o consentimento do LICENCIADO, quando este informa o e-mail do contador e envia a ele um convite para o acesso ao seu sistema.</p>
<p>16. Da remuneração e forma de pagamento</p>
<p>16.1. O LICENCIANTE concede ao LICENCIADO, de maneira gratuita, os Serviços e o acesso ao software, pelo prazo estipulado em ,  contados a partir da data de aceitação do presente TUCL, este denominado período de teste.</p>
<p>16.2. Após finalizado o período de teste, ou mesmo durante o decorrer do referido prazo, o LICENCIADO se reserva no direito de, a seu critério, continuar ou não com o uso do SOFTWARE, sem a incidência de qualquer multa rescisória.</p>
<p>16.3. Após finalizado o período de teste, se o LICENCIADO desejar continuar com a utilização do SOFTWARES, o mesmo deverá pagar, antecipadamente, ou seja, de forma pré-paga, à LICENCIANTE o valor do respectivo plano escolhido e apresentado em</p>
<p>16.4. Caso o LICENCIADO, no decorrer da vigência do presente instrumento, opte por outro plano de licenciamento superior (upgrade), os valores serão alterados de acordo com o respectivo plano escolhido. Caso esta operação gere créditos eles serão utilizados nas próximas renovações, sendo vedada a devolução de valores já pagos que se transformaram em crédito por uma operação de alteração de plano ou de recorrência.</p>
<p>16.5. Se o LICENCIADO, no decorrer da vigência do presente instrumento, optar por um plano de licenciamento inferior (downgrade), a alteração de valores será efetuada na próxima renovação, sendo vedada a devolução de valores já pagos</p>
<p>16.6. Caso o LICENCIADO, opte pelos planos anuais, no caso de suspensão dos Serviços os valores pagos não serão devolvidos.</p>
<p>16.7. A falta de pagamento de quaisquer valores nas respectivas datas de vencimento não acarretará na rescisão automática do TUCL, mas causará a suspensão do acesso do LICENCIADO ao SOFTWARE até que as pendências financeiras tenham sido regularizadas. O acesso ao SOFTWARE somente será restabelecido após a identificação pela LICENCIANTE do pagamento integral de todos os valores devidos enquanto o mesmo esteve suspenso. A identificação poderá ocorrer em até dois dias úteis após a data de pagamento pelo LICENCIADO. Caso o LICENCIADO não resolva a pendência financeira no prazo de 10 (dez) dias contados do vencimento do valor não pago, a LICENCIANTE se reserva o direito de rescindir o presente TUCL.</p>
<p>16.8. Caso o LICENCIADO não resolva a pendência financeira no prazo de 90 (noventa) dias corridos, contados do vencimento do valor não pago, a LICENCIANTE se reserva ao de apagar de forma definitiva e irrecuperável todas as informações do LICENCIADO que por ventura estejam armazenadas no SOFTWARE.</p>
<p>16.9. Os valores dos planos de licenciamento serão atualizados anualmente pelo IGPM-FGV acumulado no período, ou no caso de extinção deste, de outro índice oficial que venha a substituí-lo.</p>
<p>16.10. Caso haja mudança de planos durante a vigência do contrato, as disposições e Valor do Plano atualizado serão atualizados de acordo com a modalidade de contratação realizada pelo LICENCIADO, sendo cobrada a diferença dos planos apenas no próximo mês de vigência.</p>
<p>16.11. A LICENCIANTE poderá, a seu critério, por mera liberalidade, oferecer descontos nos valores dos planos de licenciamento. Neste caso, os descontos serão válidos por até 12 (doze) meses. A LICENCIANTE poderá renovar ou não os percentuais de desconto, podendo ainda cancelar os descontos após o período de validade, sem aviso prévio.</p>
<p>17. Do Prazo</p>
<p>17.1. O presente Contrato terá prazo de vigência indeterminado, podendo ser rescindido a qualquer tempo por ambas as Partes, mediante aviso prévio de 30 (trinta) dias; por meio de comunicação expressa à parte contrária; por meio do sistema e/ou e-mail.</p>
<p>17.2. Em caso de tratamento desrespeitoso por parte dos LICENCIADO ou da LICENCIANTE aos funcionários; o presente contrato poderá ser rescindido de forma imediata, sem observar o aviso prévio.</p>
<p>17.3. Caso o cancelamento seja feito antes do término de um período já pago, o encerramento da conta terá efeito imediato e o LICENCIADO não terá direito ao reembolso do período contratado.</p>
<p>17.4. A infração por qualquer das partes de qualquer das disposições do presente instrumento ou das disposições legais e regulamentares a que o mesmo se subordina, faculta à parte lesada, a seu exclusivo critério, rescindir o presente instrumento, independente da comunicação prévia.</p>
<p>17.5. A LICENCIANTE rescindirá imediatamente o SOFTWARE de um determinado LICENCIADO caso haja indícios de que referido LICENCIADO esteja utilizando o SOFTWARE para incitar ou cometer qualquer atitude ilegal ou que conduza a comportamento ilegal.</p>
<p>18. Restituição das Informações</p>
<p>18.1. Suspenso o acesso do LICENCIADO ao SOFTWARE, a LICENCIANTE manterá as informações do LICENCIADO armazenadas no SOFTWARE pelo período de 90 (noventa) dias, contados da suspensão de acesso. Durante este período, a LICENCIANTE tornará as informações do LICENCIADO disponíveis para serem extraídas do SOFTWARE em formato .csv.</p>
<p>18.2. Conforme descrito na cláusula acima, passados 90 (noventa) dias da suspensão do acesso do LICENCIADO ao SOFTWARE, todas as INFORMAÇÕES do LICENCIADO, incluindo as INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA e INFORMAÇÕES FINANCEIRAS, em poder da LICENCIANTE serão excluídos permanentemente do banco de dados da LICENCIANTE, independentemente de terem sido extraídas ou não pelo LICENCIADO.</p>
<p>18.3. Não obstante o disposto acima, as informações referentes à data e hora de acesso e ao endereço de protocolo de internet utilizado pelo LICENCIADO para acessar o Site e o SOFTWARE permanecerão armazenadas pela LICENCIANTE por 6 (meses) a contar da data de cada acesso realizado, independentemente do término da relação jurídica e comercial entre a LICENCIANTE e o LICENCIADO, em cumprimento ao disposto no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenados por um período maior de tempo mediante ordem judicial.</p>
<p>19. Da Rescisão</p>
<p>19.1. Ocorrerá ao final do período de testes.</p>
<p>19.2. Ocorrerá, também, pela falta de pagamento dos valores devidos.</p>
<p>19.3. O LICENCIADO poderá realizar a suspensão dos serviços prestados neste contrato a qualquer momento, desde que comunique à LICENCIANTE, por escrito, devendo pagar o saldo devedor.</p>
<p>19.4. Caso o LICENCIADO decida rescindir este contrato antes do término do prazo, o LICENCIANTE não restituirá ao LICENCIADO o saldo restante do plano de licenciamento contrato. O valor será retido pela LICENCIANTE para cobrir os custos operacionais.</p>
<p>20. Confidencialidade</p>
<p>20.1. As Partes acordam que todas as informações inseridas no SOFTWARE, informações que forem transmitidas e ou recebidas do referido sistema, serão consideradas Confidenciais, não sendo permitido a quaisquer das partes a divulgação destas informações, ressalvados os casos de ordem ou determinação judicial de Autoridade Pública. Neste sentido, a LICENCIANTE informará imediatamente ao LICENCIADO acerca de tal situação de modo que esta possa contestar e ou adotar as medidas legais que entender necessárias à proteção das informações confidenciais.</p>
<p>20.2. Não serão tratados como violação de confidencialidade as informações que estejam em domínio público à época que for desenvolvida ou fornecida;</p>
<p>20.3. A LICENCIANTE não será responsável por violações dos dados e informações acima referidos resultantes de atos de funcionários, prepostos ou de pessoas autorizadas pela LICENCIADO, e nem daquelas resultantes da ação criminosa ou irregular de terceiros “hackers” fora dos limites da previsibilidade técnica do momento em que a mesma vier a ocorrer.</p>
<p>21. Uso da Marca</p>
<p>21.1. Ao contratar o SOFTWARE, o LICENCIADO autoriza a LICENCIANTE a divulgar sua marca como cliente no site do SOFTWARE e no site da LICENCIANTE, em anúncios nas várias mídias de mercado, reportagens, eventos, testemunhais, entre outras formas de divulgação, sem que isso represente qualquer infração a este contrato ou direito comercial, direito de imagem, ou de qualquer outra natureza, para o LICENCIADO.</p>
<p>21.2. Caso o LICENCIADO prefira não ter sua marca divulgada nos termos acima, este deverá enviar comunicado formal e protocolado à LICENCIADA, que se comprometerá a não divulgar sua marca como cliente do SOFTWARE.</p>
<p>22. Das disposições Legais</p>
<p>22.1. O LICENCIADO não poderá prestar serviços a terceiros utilizando o SOFTWARE da LICENCIANTE sem autorização prévia e expressa da LICENCIANTE. A autorização de uso do SOFTWARE é fornecida por CNPJ. Desta forma, o SOFTWARE não pode operar sob o regime de multiempresa, necessitando para cada CNPJ uma licença específica;</p>
<p>22.2. Caso o LICENCIADO venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em parte, quer seja do dicionário de dados quer seja do programa, será considerado como parte do SOFTWARE fornecido pela LICENCIANTE, ficando, portanto, sua propriedade incorporada pela LICENCIANTE e seu uso condicionado a estas cláusulas contratuais;</p>
<p>22.3. Este TUCL obriga as partes e seus sucessores e somente o LICENCIADO possui licença não exclusiva para a utilização do SOFTWARE, sendo-lhe, entretanto, vedado transferir os direitos e obrigações acordados por este instrumento. Tal limitação, no entanto, não atinge a LICENCIANTE, que poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e obrigações inerentes ao presente TUCL;</p>
<p>22.4. A tolerância de uma parte para com a outra quanto ao descumprimento de qualquer uma das obrigações assumidas neste instrumento não implicará em novação ou renúncia de direito. A parte tolerante poderá, a qualquer tempo, exigir da outra parte o fiel e cabal cumprimento deste instrumento;</p>
<p>22.5. Não constitui causa de rescisão contratual o não cumprimento das obrigações aqui assumidas em decorrência de fatos que independam da vontade das partes, tais como os que configuram o caso fortuito ou força maior, conforme previsto no artigo 393 do Código Civil Brasileiro;</p>
<p>22.6. Se qualquer disposição deste TUCL for considerada nula, anulável, inválida ou inoperante, nenhuma outra disposição deste TUCL será afetada como consequência disso e, portanto, as disposições restantes deste TUCL permanecerão em pleno vigor e efeito como se tal disposição nula, anulável, inválida ou inoperante não estivesse contida neste TUCL;</p>
<p>22.7. Neste ato, o LICENCIANTE expressamente autoriza o LICENCIADO a colher e utilizar seus dados técnicos e operacionais presentes no SOFTWARE, para fins de estudos e melhorias no SOFTWARE;</p>
<p>22.8. A LICENCIANTE poderá, a seu exclusivo critério, a qualquer tempo e sem a necessidade de comunicação prévia ao LICENCIADO:</p>
<p>22.8.1. Encerrar, modificar ou suspender, total ou parcialmente, o acesso do LICENCIADO ao SOFTWARE, quando referido acesso ou cadastro estiver em violação das condições estabelecidas neste TUCL;</p>
<p>22.8.2. Excluir, total ou parcialmente, as informações cadastradas pelo LICENCIADO que não estejam em consonância com as disposições deste TUCL;</p>
<p>22.8.3. Acrescentar, excluir ou modificar o Conteúdo oferecido no site;</p>
<p>22.8.4. Alterar quaisquer termos e condições deste TUCL mediante simples comunicação ao LICENCIADO.</p>
<p>22.9. A LICENCIANTE ainda poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades do SOFTWARE, mediante comunicação prévia ao LICENCIADO, com antecedência mínima de 15 (quinze) dias, disponibilizando formas e alternativas de extrair do Site as informações, salvo nas hipóteses de caso fortuito ou força maior.</p>
<p>22.10. A LICENCIANTE poderá, por meio de comunicação ao endereço eletrônico indicado pelo LICENCIADO em seu cadastro ou por meio de aviso no Site, definir preços para a oferta de determinados conteúdos e/ou serviços, ainda que inicialmente tais serviços tenham sido ofertados de forma gratuita, sendo a utilização destes, após o referido aviso, considerada como concordância do LICENCIADO com a cobrança de tais preços.</p>
<p>22.11. Fica certo e entendido pelo LICENCIADO que somente a pessoa cadastrada pelo próprio LICENCIADO como administradora de conta poderá solicitar que as informações do LICENCIADO inseridas no SOFTWARE sejam apagadas. Ainda, o LICENCIADO declara sua ciência de que uma vez apagadas, estas não poderão mais ser recuperadas, ficando a LICENCIANTE isenta de qualquer responsabilidade por quaisquer perdas ou danos decorrentes deste procedimento solicitado pelo LICENCIADO.</p>
<p>23. Da Lei Aplicável</p>
<p>23.1. Este TUCL será regido, interpretado e se sujeitará às leis brasileiras e, o LICENCIADO e a LICENCIANTE desde logo elegem, de forma irrevogável e irretratável, o foro da Comarca da Cidade de Garibaldi, Estado do Rio Grande do Sul, para dirimir quaisquer dúvidas ou controvérsias oriundas deste TUCL, com a exclusão de qualquer outro, por mais privilegiado que seja.</p>
<p>Data da última atualização: 19/11/2024</p>

                



                <br /><br /><br />
                <h4>POLÍTICA DE PRIVACIDADE DA PLATAFORMA CONECTARPLAY</h4>
                <br />

                <p>10 de novembro de 2020</p>
<p>A presente Política de Privacidade e Proteção de Dados tem por finalidade informar os titulares sobre as medidas utilizadas pela i8 Tech LTDA referente às operações e tratamento de dados pessoais realizados no website do</p>
<p>Para prestar os serviços ofertados, alguns dados pessoais poderão ser coletados, acessados, armazenados e, possivelmente, compartilhados com parceiros. Sendo assim, nessa política vamos descrever as regras que norteiam as informações relacionada aos dados.</p>
<p>A i8 Tech coleta e trata suas informações pessoais de acordo com este aviso de privacidade e em conformidade com a Lei Geral de Proteção de Dados – Lei 13.709/2018. Este aviso fornece as informações necessárias sobre seus direitos e nossas obrigações e explica como, por que e quando tratamos seus dados pessoais.</p>
<p>Esta Política de Privacidade não se aplica a aplicativos ou sites de terceiros que você possa usar, inclusive qualquer aplicativo ou site integrado aos nossos serviços.</p>
<p>Ao visitar o site www.conectarplay.com.br e clicar no botão de aceite ou se cadastrar no CONECTAR PLAY você aceita as práticas descritas nesta Política de Privacidade.</p>
<p>1. Definições</p>
<p>1.1. Definições. Os termos iniciados em letra maiúscula indicados a seguir serão utilizados com o significado detalhado abaixo:</p>
<p>1.1.1. “i8 Tech”: é a i8 Tech Ltda, inscrita no CNPJ sob o n.º 54.464.034/0001-66, com sede na cidade de Taubaté, São Paulo, na Rua Itaboaté nº, 86 – Sala 3, Bairro Vila Olímpia, CEP 12.062-717.</p>
<p>1.1.2. “Dado Pessoal”: são informações fornecidas, coletadas ou processadas pelo i8 Tech ou pelos Usuários que identificam ou permitem identificar uma pessoa natural, tais como seu nome, e-mail e telefone.</p>
<p>1.1.3. “Software”: é a ferramenta de sistema de gestão empresarial constituída por uma aplicação de web software chamada CONECTAR PLAY de responsabilidade, propriedade e operação do i8 Tech.</p>
<p>1.1.4. “Usuário”: são os clientes da i8 Tech cadastrados no Sistema, que podem ser tanto pessoas físicas como jurídicas, pagantes ou não. Os clientes são identificados no momento de preenchimento do formulário para acesso e uso do Software.</p>
<p>2. Consentimento do Titular</p>
<p>2.1. Consentimento dos Usuários e Parceiros. Ao acessar o Software e usar os serviços disponibilizados pelo i8 Tech, o Usuário ou o Parceiro, conforme aplicável, expressamente consente e autoriza o i8 Tech a realizar o Tratamento de seus Dados Pessoais para as finalidades determinadas e previstas nesta Política de Privacidade. O consentimento do Usuário ou do Parceiro, conforme o caso, poderá ser revogado a qualquer tempo, de forma gratuita, devendo, neste caso, o Usuário ou o Parceiro entrar em contato com a equipe da i8 Tech por meio dos canais de comunicação oficiais informados nesta Política de Privacidade. A revogação do consentimento do Usuário ou do Parceiro implicará a suspensão ou o bloqueio de seu acesso ao Sistema, sem qualquer ônus ou penalidade para a i8 Tech. A revogação do consentimento não terá efeitos retroativos, sendo ratificados os tratamentos de dados pessoais realizados sob amparo do consentimento anteriormente manifestado pelo Usuário ou pelo Parceiro.</p>
<p>2.2. Consentimento de contrapartes do Usuário. Ao acessar o Sistema e usar os serviços disponibilizados pela i8 Tech, o Usuário declara e garante que obteve o consentimento expresso e por escrito (que poderá ser formalizado por meio eletrônico) de todos os titulares de Dados Pessoais que sejam contrapartes do Usuário (e.g., clientes, fornecedores, prestadores de serviços, empregados, entre outros), e que tenham seus Dados Pessoais inseridos, coletados ou processados por meio do Sistema em nome do Usuário.</p>
<p>2.3. À quem se aplica. Para todos os serviços oferecidos pela i8 Tech e a todos que de alguma forma possuem contato com os serviços, sejam eles clientes, contadores, parceiros e usuários de sites, apps ou outros recursos de fornecimento de serviços da i8 Tech.</p>
<p>3. Quais dados nós tratamos?</p>
<p>Tanto para apresentar benefícios, como dados coletados para executar as funcionalidades, a i8 Tech precisa tratar algumas informações.</p>
<p>3.1. Dados dos Usuários:</p>
<p>Coletamos dados pessoais por meio de campos de coleta de dados em nosso site: como por exemplo, fornecimento de informações no ato de cadastramento no Sistema e utilização dos dados para conversar com nossa equipe via chat na página.</p>
<p>(v) Dados gerais da empresa, como nome dos representantes legais, e-mail, telefone, endereço, documentos oficiais de identificação dos representantes e da empresa.</p>
<p>(vi) Poderemos solicitar dados bancários (cartão de crédito, nome do titular, data de validade e código de segurança) para a validação de transações financeiras e aquisição de nossos serviços e produtos.</p>
<p>Quando necessários, dados de cartão de crédito serão coletados  por meio de nosso Software.</p>
<p>(vii) Os Usuários também poderão enviar informações e dados pessoais para registrar sugestões, reclamações e solucionar dúvidas junto aos canais de atendimento da i8 Tech, antes, durante ou depois da utilização dos serviços;</p>
<p>(viii) A i8 Tech poderá solicitar imagens dos titulares clientes ou vinculados aos clientes para fins promocionais, coletas de depoimentos, campanhas e pesquisas de opinião.</p>
<p>3.2. Dados coletados automaticamente por cookies e tecnologias similares</p>
<p>Ao utilizar os serviços da i8 Tech nós poderemos coletar informações pessoais sobre os serviços que você utiliza e como você os utiliza. Essas informações são necessárias para a execução adequada dos serviços, bem como para proceder com melhorias contínuas nas funcionalidades do Software.</p>
<p>(i) Informações de Geolocalização: Ao utilizar os Software da i8 Tech nós poderemos coletar informações sobre sua localização, identificada a partir de dados como seu endereço IP ou o GPS do seu dispositivo móvel, para lhe oferecer uma experiência do usuário otimizada. A maioria dos dispositivos móveis permite que você controle ou desabilite o uso dos serviços de localização de aplicativos no menu de configurações do dispositivo.</p>
<p>(ii) Formas de Uso: Nós poderemos coletar informações sobre a forma que você utiliza os Serviços da Otimizy, como as funcionalidades mais utilizadas, alterações realizadas, tempo de uso, etc.</p>
<p>(iii) Dados de Log: Nós poderemos coletar dados de log e informações do dispositivo automaticamente quando você acessa e utiliza os Serviços da i8 Tech, ainda que você não seja um cliente.</p>
<p>(iv) Pixels e SDKs: Terceiros como o Facebook e Google podem usar cookies, web beacons e outras tecnologias para coletar ou receber informações de nosso site com a finalidade de fornecer Serviços de medição e direcionar anúncios. O Usuário poderá desativar a coleta e o uso de informações para o direcionamento de anúncios editando as configurações de anúncios dos terceiros.</p>
<p>Poderemos ainda utilizar tecnologias para armazenar informações temporárias de navegação com a finalidade de aprimorar e melhorar sua experiência na utilização de nosso produto. As principais ferramentas tecnológicas que fazem isso são os “cookies”, também sendo utilizadas tecnologias similares.</p>
<p>Com isso poderemos saber, por exemplo, quais sites que você visitou, seu Protocolo de Internet (IP), a versão de seu navegador, etc.</p>
<p>Porém você poderá configurar seu dispositivo para administrar o uso de cookies ou para alertá-lo quando estão ativos. Você pode desativar cookies e web beacons a qualquer momento ou cancelar a inscrição para receber nossos e-mails promocionais. Porém, ao desativar essas tecnologias, talvez você não consiga usar e aproveitar todos os recursos que oferecemos.</p>
<p>O procedimento para desativar é diferente para cada navegador; você precisará fazer isso em cada um dos que usa:</p>
<p>Google Chrome</p>
<p>Mozilla Firefox</p>
<p>Safari</p>
<p>Internet Explorer</p>
<p>Microsoft Edge</p>
<p>Opera</p>
<p>4. Tipos de Dados Coletados</p>
<p>4.1. Informações coletadas ao se inscrever no Sistema</p>
<p>Para ter acesso ao Software o usuário deverá fornecer os seguintes dados:</p>
<p>Plano desejado;</p>
<p>Periodicidade do pagamento</p>
<p>Razão Social;</p>
<p>CNPJ da empresa;</p>
<p>CPF do proprietário da empresa;</p>
<p>Nome da pessoa responsável pelo acesso administrativo do Sistema;</p>
<p>E-Mail da pessoa responsável</p>
<p>Aceite dos termos de uso do serviço.</p>
<p>Nome completo</p>
<p>Data de nascimento</p>
<p>E-mail</p>
<p>Certificado Digital</p>
<p>Senha do Certificado Digital</p>
<p>Email do contador</p>
<p>4.2. Informações coletadas ao fazer login no Sistema</p>
<p>Após a criação de uma conta de acesso, ao utilizar o Software os seguintes dados serão coletados de forma automática: Sistema operacional utilizado para o acesso, Endereço do protocolo de Internet (IP) Data e hora do acesso.</p>
<p>4.3. Informações que coletamos a partir de outras fontes</p>
<p>Podemos coletar informações pessoais sobre você de outras fontes, como fornecedores de serviços e parceiros, quando tiverem permissão jurídica para compartilhar as suas informações pessoais conosco. Por exemplo, se você se registrar para os nossos serviços a partir de outro site, o site poderá nos fornecer as suas informações pessoais.</p>
<p>Os titulares são responsáveis pela veracidade dos dados informados ao Sistema. Quando informarem dados pessoais de terceiros, os titulares devem se certificar que possuem autorização para fazê-lo, não tendo a i8 Tech responsabilidade sobre este ato.</p>
<p>A i8 Tech não coleta dados pessoais sensíveis por meio de seu site. Caso os titulares decidam voluntariamente informar seus dados pessoais sensíveis, os titulares expressamente consentem com o tratamento desses dados em concordância com o descrito nesta Política.</p>
<p>Durante a utilização do site do Sistema, caso os titulares sejam conduzidos, via link, a outro site, os titulares deverão se certificar da política de privacidade do site que estão visitando. A i8 Tech não se responsabiliza pela coleta e uso de dados pessoais e pelo conteúdo dos sites de terceiros.</p>
<p>5. Como utilizamos os dados coletados?</p>
<p>De modo geral, os dados coletados serão utilizados para as seguintes finalidades:</p>
<p>(i) Conceder acesso às funcionalidades dos Serviços do Sistema:</p>
<p>Emissão de nota fiscal eletrônica (modelo 55)</p>
<p>Emissão de cupons fiscais eletrônicos (Modelo 65)</p>
<p>Armazenamento de cupons fiscais eletrônicas e XML emitidos</p>
<p>Armazenamento de notas fiscais eletrônicas e XML emitidos</p>
<p>Consulta de notas fiscais eletrônicas e XML</p>
<p>Consulta de cupons fiscais eletrônicas e XML</p>
<p>Notificações automáticas</p>
<p>Visualização e Impressão de DANFE</p>
<p>Visualização e Impressão de CUPOM FISCAL</p>
<p>Envios automáticos de e-mails para a contabilidade</p>
<p>Relatórios e Dashboards</p>
<p>Para maiores informações acerca de cada funcionalidade o usuário poderá entrar em contato com o atendimento pelo e-mail suporte@conectarplay.com</p>
<p>(ii) Para oferecer material educativo, como cursos, guias, infográficos, planilhas, apostilas, webinars, e-books e outros;</p>
<p>(iii) Para realização de possíveis eventos;</p>
<p>(iv) Entrar em contato para enviar comunicações, como alertas de segurança, atualizações, para apresentar nossos Serviços, realizar operações de marketing;</p>
<p>(v) Entrar em contato para oferecer apoio e suporte e solucionar problemas;</p>
<p>(vi) Operar, proteger, realizar testes de funcionalidade, manutenção e melhorias contínuas nos serviços, melhorando a experiência dos Usuários;</p>
<p>(vii) Manter a segurança e integridade de nossos Serviços e Usuários, realizar confirmações de identidade, prevenção a fraudes, investigações internas, investigar e prevenir violações legais, regulatórias e contratuais;</p>
<p>(viii) Realizar pesquisa de desenvolvimento;</p>
<p>(ix) Realizar backups;</p>
<p>(x) Consultar e editar cadastros, acessar a conta do Usuário para realização de alterações por ele solicitadas e prestar auxílio na utilização das funcionalidades;</p>
<p>(xi) Cadastrar/alterar as formas de pagamento do Usuário pelos Serviços, consultar e gerir a situação financeira do Usuário para com a Otimizy, transferir a titularidade da assinatura; realizar estornos ou cobranças; emitir Notas Fiscais.</p>
<p>6. Finalidades de Uso dos Dados Pessoais</p>
<p>6.1. A i8 Tech LTDA se compromete a utilizar os dados pessoais coletados dos titulares para as seguintes finalidades:</p>
<p>Realização de estatísticas acerca das atividades e comportamento dos titulares;</p>
<p>Alguns dados coletados são utilizados para manter e aprimorar o funcionamento do site e do sistema e oferecer a melhor experiência ao usuário como, por exemplo, para realizar estatísticas genéricas ou anonimizadas para monitoramento de utilização do site.</p>
<p>Cadastro voluntário na newsletter;</p>
<p>6.2. O consentimento para a coleta, o armazenamento e o tratamento dos dados pessoais dos titulares, para atender às finalidades propostas pela i8 Tech na presente política de privacidade e proteção de dados é voluntário. Caso os TITULARES decidam por não consentir com a coleta dos dados, a única consequência será o não fornecimento dos serviços e/ou facilidades propostos pela i8 Tech.</p>
<p>6.3. A i8 Tech não realizará o tratamento dos dados pessoais de titulares no que se refere à presente política de privacidade e proteção de dados sem o seu consentimento.</p>
<p>6.4. A i8 Tech garante que não comercializa os dados pessoais dos titulares. No entanto, os dados pessoais dos titulares poderão ser compartilhados com agentes externos, contratados pela i8 Tech , para realização de serviços técnicos e/ou de suporte às atividades do grupo, exclusivamente para atingir os fins aqui estipulados.</p>
<p>6.5. Em respeito aos dados pessoais dos titulares, a i8 Tech requer aos agentes externos que processem os dados em consonância com seus próprios parâmetros de confidencialidade e segurança, observando-se a presente Política.</p>
<p>7. Outras possíveis utilizações dos dados</p>
<p>Para tornar sua experiência ainda melhor, eventualmente nós também poderemos realizar o tratamento dos dados pessoais para outras finalidades, a seguir listadas, mas você será informado.</p>
<p>7.1 Análises estatísticas, melhorias dos Serviços e produtos</p>
<p>Os dados (inclusive aqueles coletados pelos cookies) também poderão ser utilizados para finalidades estatísticas, para que possamos entender melhor o perfil de nossos clientes, aprimorar continuamente nosso produto e para fins de direcionamento de conteúdo específico. Nesse caso você não será identificado, sendo que seus dados irão apenas compor estatísticas sobre o uso de nosso produto, como por exemplo, quantos por cento de nossos clientes se encontram em determinada região, preferências do Usuário, quantos clientes são empresas e quantos são contadores, etc.</p>
<p>Os próprios dados e os resultados estatísticos também poderão ser utilizados para direcionar questões administrativas, comerciais e financeiras da i8 Tech, inclusive mediante direcionamento de campanhas de marketing.</p>
<p>7.2. Divulgação de campanhas, promoções e similares</p>
<p>Os dados poderão ser utilizados pela i8 Tech ou terceiros por ela contratados para o envio de informações, campanhas publicitárias, questionários, convites para eventos, pesquisas de satisfação, etc.</p>
<p>A qualquer momento você poderá cancelar o recebimento dessas informações por meio de links contidos nas próprias mensagens (unfollow) ou enviando uma solicitação para suporte@conectarplay.com</p>
<p>7.3. Para fins legais e obrigatórios</p>
<p>A i8 Tech poderá tratar dados de Usuários e dados de terceiros, inseridos por Usuários, para o cumprimento de obrigações legais ou regulatórias, compartilhando dados pessoais com autoridades públicas. Alguns exemplos, não exaustivos, desse tipo de tratamento são</p>
<p>(i) Emissão de Notas Fiscais, compartilhamento com a Receita Federal: Nome, CPF, CNPJ, endereço, telefone, e-mail, razão social, inscrição municipal/estadual, dados da venda;</p>
<p>Além disso, eventualmente os dados pessoais poderão ser tratados:</p>
<p>(i) Para auditorias internas e externas, para validações de questões de segurança da informação, buscando-se na medida do possível o sigilo e anonimização desses dados;</p>
<p>(ii) Para atender solicitações legais de autoridades públicas;</p>
<p>8. Compartilhamento</p>
<p>A i8 Tech tem como prática não transmitir dados para organizações e/ou indivíduos externos, exceto mediante consentimento livre do Usuário, informado e inequívoco, ou quando o consentimento for dispensável no escopo das hipóteses legítimas de tratamento de dados pessoais, previstas na legislação vigente.</p>
<p>São hipóteses de compartilhamento:</p>
<p>(i) Quando permitido, nos termos da lei aplicável, poderemos tratar determinados dados pessoais, como endereço de e-mail, para compartilhá-los com plataformas de mídia social, como Facebook e Google, para gerar leads, direcionar tráfego para nosso site ou de outra forma, promover nossos Serviços. Essas atividades de tratamento baseiam-se em nosso interesse legítimo em realizar atividades de marketing para oferecer Serviços que possam ser de seu interesse.</p>
<p>(ii) Qualquer dúvida sobre como provedores de aplicações, como por exemplo Facebook e Google, processam suas informações pessoais deve ser direcionada para esses provedores.</p>
<p>(iii) A i8 Tech poderá compartilhar os dados dos Usuários com autoridades públicas, tais como autoridades fiscais, fazendárias, judiciárias e policiais, ou terceiros autorizados, se e na medida necessária ou permitida para atender a lei, em situações como:</p>
<p>Para cumprir com obrigações legais;</p>
<p>Para cumprir com uma solicitação legal válida e responder a alegações feitas contra a i8 Tech;</p>
<p>Para promover ou se defender em ações judiciais;</p>
<p>Para responder a uma solicitação legal válida relacionada a investigações criminais, suspeitas de atividade ilegal ou qualquer outra atividade que possa expor a i8 Tech e os Usuários a responsabilização legal;</p>
<p>Para aplicar e administrar nossos Termos e Condições Gerais;</p>
<p>Para realizar a segurança física e cibernética da i8 Tech, em todos seus ativos, e de seus Usuários.</p>
<p>Para execução dos Serviços a i8 Tech poderá compartilhar dados dos Usuários com prestadores de serviço terceirizados, que atuam em diversos processos, tais como:</p>
<p>Armazenamento de informações em nuvem;</p>
<p>Verificação da identidade dos Usuários, para prevenção a fraudes e avaliação de risco de crédito;</p>
<p>Verificação de informações em comparação com bancos de dados públicos;</p>
<p>Pesquisa, desenvolvimento e manutenção dos Softwares;</p>
<p>Fornecimento dos Serviços da i8 Tech por meio de plataformas de terceiros e ferramentas de software (por exemplo, por meio da integração com APIs)</p>
<p>Atendimento ao Usuário;</p>
<p>Promoção de marketing, inclusive serviços de publicidade em redes sociais, prospecção de leads e análise de dados;</p>
<p>Prestação de serviços de consultoria, advocacia, contabilidades e outros prestadores de serviços profissionais;</p>
<p>Processamento e garantia da segurança de pagamentos.</p>
<p>Os colaboradores da i8 Tech têm acesso limitado aos dados dos Usuários para realizar tarefas específicas e estão contratualmente obrigados a proteger as informações, empregando-as somente para as finalidades contratadas e sob a supervisão da i8 Tech.</p>
<p>A i8 Tech LTDA possui compromisso com a segurança do tratamento dos dados pessoais dos usuários por meio da implementação de mecanismos avançados de tecnologia da informação, que adotam procedimentos técnicos, físicos e lógicos de segurança normalmente utilizados pelo mercado. A adoção de tais procedimentos almeja a proteção dos dados pessoais contra a perda, mau uso, alteração, destruição ou vazamento.</p>
<p>9. Segurança de suas informações</p>
<p>9.1. A i8 Tech adota medidas técnicas e administrativas  para a proteção da privacidade dos dados dos clientes, utilizando todos os esforços para manter a estrita confidencialidade, integridade e disponibilidade restrita às pessoas autorizadas.</p>
<p>9.2. Tanto nosso site, por meio do qual você acessa o produto Sistema, quanto o backup de nossos dados, estão na Amazon Web Services ou Microsoft Azure, os maiores e mais confiáveis datacenters do mundo.</p>
<p>9.3. Além disso, utilizamos as melhores práticas de segurança do mercado, além de controles de acesso físico e digital, criptografia, medidas contra a destruição ou uso ilegal, gestão de vulnerabilidades, tratamento de incidentes e acessos não autorizados ou em desconformidade com a presente Política de Privacidade ou Termo de Uso do produto.</p>
<p>9.4. Qualquer membro da i8 Tech que possui acesso às informações coletadas é informado sobre a sua natureza confidencial, além de manter compromisso de agir em conformidade com esta Política.</p>
<p>9.5. Os titulares deverão adotar as medidas preventivas necessárias ao uso da internet, incluindo o uso de antivírus, senha de acesso pessoal e outras medidas técnicas e gerenciais, com vista a evitar falhas, invasões ou outros inconvenientes no acesso às ferramentas disponibilizadas pela empresa. A OTIMIZY SISTEMAS não será responsável por eventuais danos decorrentes de culpa exclusiva dos titulares e/ou de terceiros.</p>
<p>9.6. Os dados de acesso ao sistema são armazenados de forma criptografada. As informações são protegidas com a tecnologia SSL (Secure Socket Layer) para que, ao trafegar entre os nossos servidores para o computador do usuário, estes dados permaneçam em sigilo. Essa tecnologia visa ao impedimento que as informações sejam transmitidas ou acessadas por terceiros.</p>
<p>9.7. Por todo o tempo em que o usuário mantiver a sua conta no Sistema, todas as informações coletadas ou informadas serão armazenadas e processadas em servidores com alto padrão de segurança, bem como réplicas e cópias de segurança dos seus dados.</p>
<p>10. Responsabilidade dos Usuários</p>
<p>Sem prejuízo às demais obrigações previstas nesta Política de Privacidade ou no Termo de Uso, para a adequada utilização do Sistema é preciso que você se comprometa a:</p>
<p>(i) Ser o único responsável pela guarda e confidencialidade do login e senha, não podendo fornecê-los a terceiros;</p>
<p>(ii) Fornecer informações de cartão de crédito, senhas de qualquer natureza utilizando os recursos existentes em nosso Software.</p>
<p>(iii) Nos comunicar sobre toda e qualquer suspeita ou constatação de violação aos Termos de Uso ou à Política de Privacidade, inclusive acessos não autorizados à sua conta, movimentações suspeitas, boletos que você desconhece, etc.</p>
<p>(iv) Nos consultar, via site ou canais de contato, sempre que receber informações ou comunicados sobre promoções, campanhas e pesquisas recebidas por e-mail, para verificação de sua veracidade;</p>
<p>(v) Prestar informações verídicas, honestas e completas, responsabilizando-se por toda e qualquer informação incorreta ou falsa;</p>
<p>(vi) Responsabilizar-se por todos os dados de terceiros (ex. dados pessoais de seus clientes, de seu contador);</p>
<p>(vii) Nos comunicar sobre toda e qualquer alteração de seus dados, de modo que para todos os fins legais os últimos dados fornecidos serão considerados como corretos, autênticos e suficientes para o cumprimento desta Política e do Termo de Uso e Condições Gerais;</p>
<p>Por questões de segurança, nos reservamos no direito de suspender ou cancelar a conta do Usuário em caso de suspeita ou descumprimento dos Termos de Uso ou da Política de Privacidade, sem prejuízo de demais medidas judiciais e administrativas.</p>
<p>11. Manutenção dos dados</p>
<p>Consideradas as situações, conservaremos os dados dos Usuários pelo prazo de retenção necessário, considerada a situação que justifica o tratamento:</p>
<p>(i) se para cumprimento de obrigação legal ou regulatória, pelo prazo indicado na norma em questão;</p>
<p>(ii) se para o cumprimento de contrato, até o alcance dos objetivos da relação contratual; e</p>
<p>(iii) se fundado no legítimo interesse, que sempre será precedido de uma análise de impacto de privacidade e realizado dentro dos limites da finalidade que o justifica, até que este cesse.</p>
<p>Poderemos ainda conservar seus dados pessoais, independente do seu pedido de eliminação e consentimento, conforme artigo 16 da Lei Geral de Proteção de Dados, nas seguintes situações e respeitadas as seguintes finalidades:</p>
<p>Cumprimento de obrigação legal ou regulatória;</p>
<p>Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização;</p>
<p>Transferência a terceiro, respeitados os direitos do titular e previsão nas leis de proteção de dados e privacidade;</p>
<p>Nosso uso exclusivo, desde que vedado acesso por terceiro, e desde que anonimizados os dados.</p>
<p>12. Direitos dos Usuários</p>
<p>Para o cumprimento de obrigação legal ou regulatória, pelo prazo indicado na norma em questão;</p>
<p>Para o cumprimento de contrato, até o alcance dos objetivos da relação contratual; e</p>
<p>Se fundado no legítimo interesse, que sempre será precedido de uma análise de impacto de privacidade e realizado dentro dos limites da finalidade que o justifica, até que este cesse.  Os Usuários poderão gerenciar o uso de seus dados, podendo para tanto:</p>
<p>(i) Pedir a exclusão de seus dados, ressalvado impeditivo legal;</p>
<p>(ii) Pedir a alteração ou correção de seus dados</p>
<p>(iii) Pedir informações sobre o compartilhamento de seus dados;</p>
<p>(iv) Restringir, na medida do possível, o uso de seus dados, inclusive no que diz respeito ao recebimento de campanhas publicitárias e utilização de Cookies;</p>
<p>(v) Solicitar cópia de seus dados em formato legal e adequado;</p>
<p>(vi) Revogar o consentimento para o tratamento de seus dados, sendo avisado neste caso sobre as consequências;</p>
<p>(vii) Solicitar que a i8 Tech cesse o tratamento de seus dados para fins de marketing, enviando para tanto, um e-mail para suporte@conectarplay.com</p>
<p>A i8 Tech atenderá aos pleitos dos Usuários, mas informa que esses direitos não serão absolutos, de modo que algumas situações podem resultar em atendimento parcial do pedido ou até negativa. Nessas situações, prestará os esclarecimentos e justificativas necessárias.</p>
<p>13. Contato e relatos de incidentes</p>
<p>13.1. Para o exercício de seus Direitos ou qualquer comunicação necessária com a i8 Tech em razão da utilização do produto ou por conta da presente Política de Privacidade, você poderá utilizar dos seguintes Canais de Comunicação:</p>
<p>suporte@conectarplay.com</p>
<p>O contato do cliente e sua identidade poderão ser validados pela i8 Tech, razão pela qual informações e dados podem ser solicitados no momento do atendimento.</p>
<p>13.2. Para se precaver em caso de incidentes de privacidade que importem em risco de dano aos Titulares de Dados Pessoais, a i8 Tech  também utiliza os canais de contatos supracitados, para recebimento de notícias ou relatos de incidentes.</p>
<p>13.3. As comunicações serão recebidas pelo responsável DPO, que nada mais é que o “Encarregado de dados”. É o profissional responsável pela proteção de dados dentro da empresa, garantindo a segurança das informações, tanto dos clientes quanto da própria organização. O DPO verificará o ocorrido e aplicará o “Procedimento de Respostas a Incidentes da i8 Tech”.</p>
<p>14. Responsável pelo política de privacidade</p>
<p>i8 TECH, pessoa jurídica de direito privado, com sede na cidade de Taubaté, São Paulo, na Rua Itaboaté nº, 86 – Sala 3, Bairro Vila Olímpia, CEP 12.062-717, inscrita no CNPJ sob o número 54.464.034/0001-66. Nosso responsável pela proteção de dados é o Sr. Ricardo Nascimento Sartori, que pode ser contatado pelo e-mail ricardo.sarttori@i8tech.com.br.</p>
<p>As principais atribuições do Encarregado envolvem, sem prejuízo de demais atividades estabelecidas em políticas e procedimentos específicos que compõem a Política de Privacidade:</p>
<p>a) Gestão da Política de Privacidade;</p>
<p>b) Desenvolvimento, manutenção, e propositura de revisão de procedimentos e políticas de privacidade da Organização, inclusive desta Política;</p>
<p>c) Fiscalização do cumprimento de procedimentos e políticas do Política de Privacidade;</p>
<p>d)  Monitoramento do nível de conformidade da Organização, por meio de análises de diagnóstico recorrentes, com a definição de planos de ação para melhorar o treinamento e a clareza dos documentos que integram o Política de Privacidade;</p>
<p>e)    Atuação como ponto de contato para a Autoridade Nacional de Proteção de Dados e os Titulares;</p>
<p>f)     Recebimento de requisições dos Titulares, e</p>
<p>g)    Preparo dos Relatórios de Impacto à Proteção de Dados Pessoais, com apuração e revisão dos riscos das atividades.</p>
<p>Por fim, o Encarregado deve auxiliar os colaboradores da i8 Tech e esclarecer-lhes qualquer dúvida sobre a Política de Privacidade e a forma correta de Tratamento de Dados Pessoais a ser adotada durante a execução de suas atividades.</p>
<p>15. Alterações a esta Política</p>
<p>Esta Política poderá ser modificada a qualquer tempo, mediante prévia comunicação por meio do website do Sistema () e/ou através de correspondência eletrônica dirigida aos titulares, a ser enviada ao e-mail informado à empresa.</p>
<p>16. Aviso Legal</p>
<p>A i8 Tech é um provedor de SaaS (Software as a Service), atuando, no âmbito do Sistema, como OPERADOR DE DADOS; e portanto, e não controla e de nenhuma forma é responsável pela coleta e tratamento de dados inseridos no âmbito do Sistema; não sendo, portanto, responsável pelo uso que nossos clientes são aos dados pessoais ali tratados.</p>
<p>Esta Política de Privacidade e Proteção de Dados entra em vigor em 20 de novembro de 2023.</p>
<p>17. Foro</p>
<p>Nossa Política de Privacidade está em conformidade com a legislação da República Federativa do Brasil. Qualquer questão decorrente do relacionamento entre o cliente e a i8 Tech serão tratadas no Foro da Comarca de Taubaté, Estado de São Paulo</p>
<p>Data da última atualização: 19/11/2024</p>


                <br />
            </div>
        </div>
    )
}


