/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { Request, Req } from '../../scripts/Request';
import { FormGenerator } from '../Form/FormGenerator';
import { WaitObject } from '../../scripts/Wait';
import { AppServer } from '../../scripts/Loadbalancer';
import { useParams, useNavigate } from 'react-router-dom';
import { CacheDB } from '../../scripts/CacheDB';
import './index.css';
import { IsCPF, IsCNPJ } from '../../scripts/StringUtils';
import { Terms } from './Terms';


let cliente_auto_fill = false;


export default function Payment() {
    const component_name = "payment";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [termsRead, setTermsRead] = useState(false);
    const [termsScrolled, setTermsScrolled] = useState(false);
    const [isSaveable, setIsSaveable] = useState(false);
    const [scrollArr, setScrollArr] = useState([0, 0]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const _formUserRef = useRef();
    const _loadInfo = useRef({});

    const navigate = useNavigate();

    let { Hash } = useParams();


    //#region Effects
    useEffect(() => {
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("#root");
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        document.querySelector('.terms').addEventListener('scroll', function() {
            var element = this;
            //console.log((element.scrollHeight - element.scrollTop), element.clientHeight)
            if (element.scrollHeight - element.scrollTop < element.clientHeight + 80) {
                setScrollArr([(element.scrollHeight - element.scrollTop), element.clientHeight])
                setTermsScrolled(true);
            }
        });

        //TestPipeline();
    }
    //#endregion Init


    //#region Loaders

    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = async(event) => {

        WaitObject(_formUserRef.current).then(async() => {
            
            await _formUserRef.current.Value("plano_id", 4);
            await _formUserRef.current.Select("uf_codigo", [], "/assets/json/estados.json", "GET", "", "");
            await _formUserRef.current.Value("uf_codigo", 26);
            await _formUserRef.current.Select("plano_id", [], AppServer() + "api/Pay/Planos", "GET", "", "");
            await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/Pay/Cidades", "POST", { uf_codigo: 26 }, "");

            let user_data = await new Request().Run("api/Pay/Load?hash=" + Hash, "GET", "", "");

            if(user_data){
                _loadInfo.current = user_data;
                await _formUserRef.current.Load(user_data);
                if(user_data.uf_codigo && user_data.uf_codigo !== "") await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/Pay/Cidades", "POST", { uf_codigo: user_data.uf_codigo }, "");
            
                if(user_data.cpfCnpj) blur_cpfCnpj(user_data.cpfCnpj);
            }

            Preload();
        });
    }


    const postalCode = async value => {
        if(!cliente_auto_fill) {
            let _address = await _formUserRef.current.GetValue("address");
            if(value.length === 9 ) {
                Promise.resolve(new Request().Run("https://viacep.com.br/ws/" + value.replace('-', '') + "/json", "GET", "", "", 0*24*60*1000)).then(async(data) => {
                    if(data.toString() !== "error") {
                        //console.log(data);
                        await _formUserRef.current.Value("address", data.logradouro);
                        await _formUserRef.current.Value("province", data.bairro);
                        let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                        let _uf = _uf_options.find(u => u.sigla === data.uf).id;
                        await _formUserRef.current.Value("uf_codigo", _uf);
                        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/Pay/Cidades", "POST", { uf_codigo: _uf }, user.token);

                        let city = data.localidade;
                        city = city.toLocaleUpperCase();
                        
                        let cities = await _formUserRef.current.GetOptions("cidade_codigo");
                        let _id = cities.find(c => c.name === city).id;
                        await _formUserRef.current.Value("cidade_codigo", _id);
                    }
                }).catch(() => {
                
                });
            }
        }
    }


    const blur_cpfCnpj = async(value) => {
        if(!IsCPF(value) && !IsCNPJ(value)) {
            setIsSaveable(false);
        } else {
            setIsSaveable(true);
        }
    }


    const uf_codigo = async (value) => {
        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/Pay/Cidades", "POST", { uf_codigo: value }, user.token);
    }


    const plano_id = value => {
        WaitObject(_formUserRef.current).then(async() => {
            setTimeout(() => {
                 let _modelo = _formUserRef.current.GetObject("plano_id").type.options.find(o => o.id === parseInt(value));
                _formUserRef.current.SetObject("div_plano_description", {height: '100px'});
                document.getElementById("div_plano_description").innerHTML = _modelo.descricao;
             }, 110);
        });
     }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _cpf = _formUserRef.current.GetValue("cpfCnpj");
        if(!IsCPF(_cpf) && !IsCNPJ(_cpf)) {
            window.swal("Alerta", "CPF ou CNPJ invalido!", "warning");
            return;
        }


        setPageLoading(true);
        let _result = await _formUserRef.current.Post(AppServer()+"api/Pay/Sign", {uuid: Hash}, "");
        if(_result.toString() !== "error") {
            setPageLoading(false);
            window.location = _result.toString();
        } else if(_result.toString() === "loaded") {
            setPageLoading(false);
            window.swal("Sucesso", "A informações de pagamento forão enviadas para seu E-mail", "success");
            navigate("/Complete");
        } else {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro! Tente novamente mais tarde.", "warning");   
        }
        
    }


    const _HandleTerms = (event) => {
        setTimeout(() => {
            setTermsRead(event.target.checked);
        }, 400)
    }
    //#endregion Handlers


    //#region Functions
    const Preload = (value = 1) => {
        try {
            let _modelo = _formUserRef.current.GetObject("plano_id").type.options.find(o => o.id === parseInt(value));
            _formUserRef.current.SetObject("div_plano_description", {height: '100px'});
            document.getElementById("div_plano_description").innerHTML = _modelo.descricao;
        } catch(e) {}
    }
    //#endregion Functions


    //#region Tests
    const TestPipeline = () => {
        console.log("TestPipeline");
        document.addEventListener('keydown', async function (e) {
            var char = e.keyCode || e.which;
            var letra = String.fromCharCode(char).toLowerCase();
            if (letra === "t") {
                await _formUserRef.current.Value("plano_id",1);
                await _formUserRef.current.Value("name", "Jaonel Teste Filho");
                await _formUserRef.current.Value("cpfCnpj", "01504885058");
                await _formUserRef.current.Value("email", "teste@agenciavitrae.com.br")

                await _formUserRef.current.Value("phone", "(12) 3622-3396");
                await _formUserRef.current.Value("mobilePhone", "(12) 98122-1043");
                await _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Value("cidade_codigo", 9726);

                await _formUserRef.current.Value("postalCode", "12091-540");
                await _formUserRef.current.Value("address", "Rua das dornas");
                await _formUserRef.current.Value("addressNumber", "223");
                await _formUserRef.current.Value("province", "Jardim logo ali");
                await _formUserRef.current.Value("complement", "Casa");   
                await _formUserRef.current.Value("additionalEmails", "");
                await _formUserRef.current.Value("company", "");
                await _formUserRef.current.Value("municipalInscriptiony", "");
                await _formUserRef.current.Value("stateInscription", "");
                await _formUserRef.current.Value("externalReference", "");

                Preload(1);
                blur_cpfCnpj("01504885058");
            }
        });
    }
    //#endregion Tests


    return (
        <div className="Payment">
            <br /> 

            <br /> 

            <div className="container-fluid">
                <div className="row">
                    
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <center>
                                <img src="/assets/images/logo.png" />
                                </center>
                            </div>

                            <div className="card-body pb-2">
                               
                                <form name="fuser" onSubmit={(event) => _HandleSubmit('pagamento', event)} autoComplete="off" id="form-contact" className="GeneratedForm" noValidate>
                                    <div className="row">
                                        <div className="col-md-12 pt-1 pb-1">
                                            <FormGenerator ref={_formUserRef} name={'pagamento'} url={'/assets/forms/pagamento.json'} execRef={Exec} initial_trigger={false}></FormGenerator>
                                            <div style={{clear:'both'}}></div>
                                            

                                            <Terms />

                                            <center>
                                                    <hr />
                                                    
                                                    {termsRead &&  (<button className="btn btn-lg btn-primary mt-3 submit" type="submit" disabled={!isSaveable}><i className='mdi mdi-credit-card-check'></i> &nbsp; Assinar Plano</button>)}
                                                    {!termsRead && termsScrolled && (<div className="checkTerms">
                                                            <div>
                                                                <input type="checkbox" onClick={_HandleTerms}/>
                                                            </div>
                                                            <div>
                                                                <small>Li e estou ciente dos termos de contratação aceitando-os integralmente.</small>
                                                            </div>
                                                    </div>)}
                                                </center>
                                        </div> 
                                    </div>
                                </form>
                                
                            </div>
                        </div>

                        <br /><br /><br /><br />
                    </div>


                   
                </div>
            </div>
            <br />
        </div>
    )
}

